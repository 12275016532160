<template>
  <div class="sub-users">
    <header class="title-bar">
      <div class="row justify-content-between">
        <div class="col-8 align-self-center">
          <h1 class="title">{{ $t('users.header_title') }}</h1>
        </div>

        <div class="col-4 align-self-center text-right">
          <a @click="openSubUserPopup()" class="btn primary">
            <i class="fas fa-plus"></i>
            <span class="d-none d-lg-inline pl-lg-1" data-testid="add-sub-user">{{ `${$t('users.add_user_btn')}` }}</span>
          </a>
        </div>

      </div>
    </header>

    <loading :loading="is_loading"></loading>

    <div v-if="!is_loading">
      <div class="panel mobile-table">
        <table>
          <thead>
            <tr>
              <th>{{ $t('users.table_headers.first_name') }}</th>
              <th>{{ $t('users.table_headers.last_name') }}</th>
              <th>{{ $t('users.table_headers.organization_name') }}</th>
              <th>{{ $t('users.table_headers.email') }}</th>
              <th></th>
              <th>{{ $t('users.table_headers.company_role') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr :class="{'current-user' : logged_email === user.email }" v-for="user in user_list" @click="openSubUserPopup(user)">
              <td>{{ user.first_name }}</td>
              <td>{{ user.last_name }}</td>
              <td>{{ user.organization.name }}</td>
              <td>{{ user.email }}</td>
              <td>
                <div v-if="logged_email === user.email">
                  <span class="badge success">{{ $t('users.current_user_badge') }}</span>
                </div>
              </td>
              <td>{{ user.company_role }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <SubUsersPopup :user="selected_user" v-if="popup" @update-popup="togglePopup" :popup="popup"/>

  </div>
</template>

<script setup>
  import SubUsersPopup from '../components/subusers/SubUsersPopup.vue';
  import {ref, reactive, inject, computed} from 'vue';

  const event_hub = inject('event-hub');
  const axios = inject('axios');
  const sty = inject('sentry');

  const popup = ref(false);
  const user_list = ref([]);
  const is_loading = ref(false);

  const selected_user = reactive({
    first_name: '',
    last_name: '',
    email: '',
    company_role: '',
    organization: {
      _id: ''
    },
    language: ''
  });

  const logged_email = computed(() => {
    return JSON.parse(localStorage.user_data).email;
  });

  const togglePopup = (pop_up) => {
    popup.value = pop_up;
  };

  const openSubUserPopup = (user) => {
    if (user) {
      Object.assign(selected_user, user);
    } else {
      for (const key in selected_user) {
        selected_user[key] = '';
      }
    }
    popup.value = true;
  };

  const getUsers = () => {
    is_loading.value = true;

    axios.get(`${process.env.API_URL}/users`)
      .then((res) => {
        user_list.value = res.data;
        is_loading.value = false;
      })
      .catch((error) => {
        is_loading.value = false;
        sty.captureException(error);
      });
  };

  popup.value = false;
  getUsers();
  event_hub.on('refresh-subusers', getUsers);
</script>
