<template>
  <div class="text-center">

    <div class="text-center" v-if="is_loading">
      <img src="assets/loading.gif">
      <p>{{ $t('login.email_validation.loading') }}</p>
    </div>

    <div v-if="!is_loading">
      <h3>{{ $t('login.email_validation.title') }}</h3>
      <p>{{ $t('login.email_validation.subtitle') }}</p>
      <div v-if="!success">
        <input type="email" :placeholder="$t('login.email_validation.placeholder_email')" @keyup.enter="sendEmailRequest" v-model="email">
        <button @click="sendEmailRequest" class="primary">
          {{ $t('login.email_validation.reset_btn') }}
        </button>
      </div>
      <aside class="alert success" v-if="success">
        <i class="fa fa-check-circle"></i>
        {{ $t('login.email_validation.alert_msg') }}
      </aside>
      <aside class="alert danger" v-if="invalid_email">
        <i class="fa fa-times-circle"></i>
        {{ $t('login.email_validation.error_invalid_email') }}
      </aside>
      <aside class="alert danger" v-if="error">
        <i class="fa fa-times-circle"></i>
        {{ $t('login.error_processing') }}
      </aside>
    </div>

  </div>
</template>

<script setup>
  import {ref, inject} from 'vue';
  import {emailValidation} from '../../../js/helpers';

  const axios = inject('axios');
  const sty = inject('sentry');

  const is_loading = ref(false);
  const error = ref(false);
  const email = ref('');
  const success = ref(false);
  const invalid_email = ref(false);

  const sendEmailRequest = () => {
    is_loading.value = true;
    error.value = false;
    invalid_email.value = false;

    if (emailValidation(email.value)) {
      axios.post(`${process.env.API_URL}/account/password/email`, {email: email})
        .then(() => {
          success.value = true;
          is_loading.value = false;
        })
        .catch((error) => {
          error.value = true;
          is_loading.value = false;
          sty.setContext('email', email);
          sty.captureException(error);
        });
    } else {
      invalid_email.value = true;
      is_loading.value = false;
    }
  };
</script>
