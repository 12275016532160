import {ref, watch} from "vue";

const useEmailEdition = (email_account_org) => {
  const is_email_editable = ref(false);
  const local_org = JSON.parse(localStorage.user_data).organization;

  const updateEmailEditability = () => {
    if (!email_account_org._id) {
      is_email_editable.value = true;

      return;
    }

    const connected_org_level = local_org.org_level;
    const email_org_level = email_account_org.org_level;

    is_email_editable.value = connected_org_level < email_org_level;
  };

  updateEmailEditability();
  watch(() => email_account_org, updateEmailEditability, {
    deep: true
  });

  return {
    is_email_editable
  };
};

export {useEmailEdition};
